/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://1d6b98abb755b837251990dd8adf2f95@sentry.naukosfera.com/5'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = 'dd1e0872ad6d6d47349b233c5f3cf0903e4cb8fa'
